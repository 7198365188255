import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Form from "../components/form"
import ScrollAnimation from "react-animate-on-scroll"

import { FormPageStyle } from "../styles/recrutamento"

const Recrutamento = props => {
  const { contactPage1 } = props.data.dataJson || {}

  return (
    <>
      <SEO
        title="Carreira"
        image="/images/recrutamento.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/recrutamento"
        description="Gostava de fazer parte da equipa Sicarpo? Preencha o formulário."
      />
      {props.data && props.data.dataJson ? (
        <FormPageStyle>
          <div
            className="startArea"
            style={{ backgroundImage: `url(${contactPage1.img.publicURL})` }}
          >
            <div className="container">
              <div className="left">
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <h1 className="title">{contactPage1.title}</h1>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <p className="text">{contactPage1.text}</p>
                </ScrollAnimation>
              </div>
              <div className="right">
                <Form formType="recrutamento" />
              </div>
            </div>
          </div>
        </FormPageStyle>
      ) : (
        <div className="isLoading">A carregar...</div>
      )}
    </>
  )
}

export default Recrutamento

export const RecrutamentoQuery = graphql`
  query RecrutamentoQuery {
    dataJson {
      contactPage1 {
        img {
          publicURL
        }
        title
        text
      }
    }
  }
`
